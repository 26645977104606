// ---------------- DEPENDENCIES --------------- //

import { makeStyles } from '@material-ui/styles';

// ---------------- MAIN ---------------- //

export const useStyles = makeStyles({
  app: {
    height: '100vh',
  },
  routeContainer: {
    height: '100%',
  },
});
