// --------------- DEPENDENCIES -------------- //

import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// --------------- LOCAL DEPENDENCIES -------------- //

// Components.
import Navbar from '../../components/Navbar';
import About from '../About';
import Work from '../Work';
import Landing from '../Landing';

// Constants.
import { Routes } from '../../constants';

// Styles.
import { useStyles } from './styles';

// --------------- MAIN --------------- //

const rootVariants = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      delay: 0.6,
      when: 'afterChildren',
    },
  },
};

function App(props) {
  const classes = useStyles(props);

  const location = useLocation();

  return (
    <div className={classes.app}>
      <AnimatePresence
        exitBeforeEnter
      >
        <motion.div
          key={location.pathname}
          className={classes.routeContainer}
          variants={rootVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Switch location={location}>
            <Route exact path={Routes.Landing} component={Landing} />
            <Route exact path={Routes.About} component={About} />
            <Route exact path={Routes.Work} component={Work} />
          </Switch>
        </motion.div>
      </AnimatePresence>
      <Navbar />
    </div>
  );
}

function AppWithRouter(props) {
  return (
    <Router>
      <App {...props} />
    </Router>
  );
}

export default AppWithRouter;
