// ---------------- DEPENDENCIES --------------- //

import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../constants';

// ---------------- MAIN ---------------- //

export const useStyles = makeStyles({
  skillItem: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    '& > img': {
      height: 100,
      width: 100,
      padding: 20,
    },
    '& > span': {
      color: Colors.White,
      fontWeight: 700,
      letterSpacing: '1px',
    },
  },
});
