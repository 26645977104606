import {
  useState,
  useRef,
  useReducer,
  useCallback,
} from 'react';

// Hook adaption of: https://medium.com/@agm1984/use-react-to-make-a-photo-follow-the-mouse-aka-transform-perspective-or-tilt-7c38f1b3a623

const defaultSettings = {
  reverse: false,
  max: 35,
  perspective: 1000,
  easing: 'cubic-bezier(0.03, 0.98, 0.52, 0.99)',
  scale: '1.1',
  speed: '1000',
  scrollSpeed: 100,
  transition: true,
  axis: null,
  reset: true,
};

const dimsInitState = {
  width: null,
  height: null,
  left: null,
  top: null,
};
const scrollInitState = {
  lastPos: null,
  delta: null,
};

function reducer(state, payload) {
  return payload;
}

export default function useTileTilt(options = {}) {
  const [settings] = useState({
    ...defaultSettings,
    ...options,
  });
  const [style, setStyle] = useState({
    transition: `${settings.speed}ms ${settings.easing}`,
  });
  const [reverse] = useState(settings.reverse ? -1 : 1);
  const [dims, updateDims] = useReducer(reducer, dimsInitState);
  const [scroll, updateScroll] = useReducer(reducer, scrollInitState);
  const transitionTimeoutRef = useRef(null);
  const updateCallRef = useRef(null);
  const eventRef = useRef(null);
  const hoverElemRef = useRef(null);
  const { width, height, left, top } = dims;
  const { lastPos, delta } = scroll;

  function handleMouseEnter() {
    updateElementPosition();
  }

  function reset() {
    updateScroll({
      lastPos: null,
      delta: 0,
    });
    window.requestAnimationFrame(() => {
      setStyle({
        ...style,
        transform: `perspective(${settings.perspective}px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)`,
      });
    });
  }

  function handleMouseMove(event = null) {
    if (event) {
      event.persist();
    }

    if (updateCallRef.current !== null) {
      window.cancelAnimationFrame(updateCallRef.current);
    }

    eventRef.current = event;
    updateCallRef.current = requestAnimationFrame(update.bind(null, event));
  }

  function handleMouseLeave() {
    if (settings.reset) {
      reset();
    }
  }

  function getValues(event) {
    const isMouse = event && event.nativeEvent;
    let tiltX;
    let tiltY;

    if (isMouse) {
      const x = (event.nativeEvent.clientX - left) / width;
      const y = (event.nativeEvent.clientY - top) / height;

      const _x = Math.min(Math.max(x, 0), 1);
      const _y = Math.min(Math.max(y, 0), 1);

      tiltX = (reverse * (settings.max / 2 - _x * settings.max)).toFixed(2);
      tiltY = (reverse * (_y * settings.max - settings.max / 2)).toFixed(2);
    } else {
      const _d = -Math.min(Math.max(delta, -1), 1);

      tiltX = 0;
      tiltY = (reverse * _d * settings.max * 0.7).toFixed(2);
    }

    return {
      tiltX,
      tiltY,
    };
  }

  function updateElementPosition() {
    const rect = hoverElemRef.current.getBoundingClientRect();

    updateDims({
      width: hoverElemRef.current.offsetWidth,
      height: hoverElemRef.current.offsetHeight,
      left: rect.left,
      top: rect.top,
    });
  }

  function update(event) {
    const values = getValues(event);

    setStyle({
      ...style,
      transform: `perspective(${settings.perspective}px) rotateX(${settings.axis === 'x' ? 0 : values.tiltY}deg) rotateY(${settings.axis === 'y' ? 0 : values.tiltX}deg) scale3d(${settings.scale}, ${settings.scale}, ${settings.scale})`,
    });

    updateCallRef.current = null;
  }

  const handleScroll = useCallback((status) => {
    const newPos = status.offset.y;
    let uDelta = delta;

    if (lastPos !== null) {
      uDelta = (newPos - lastPos) * 0.02;
    }

    const uLastPos = newPos;
    clearTimeout(transitionTimeoutRef.current);
    transitionTimeoutRef.current = setTimeout(handleMouseLeave, settings.scrollSpeed);

    updateScroll({
      delta: uDelta,
      lastPos: uLastPos,
    });

    handleMouseMove();
  }, [lastPos, delta]);

  return [
    hoverElemRef,
    {
      onMouseEnter: handleMouseEnter,
      onMouseMove: handleMouseMove,
      onMouseLeave: handleMouseLeave,
    },
    { onScroll: handleScroll },
    style,
  ];
}
