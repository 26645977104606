import {
  useRef,
  useEffect,
  useState,
} from 'react';
import Scrollbar from 'smooth-scrollbar';

const defaultOptions = {};

// The use of 2 useEffects here may seem redundant, however this is
// due to the fact that a new onScroll callback is created for every
// scroll event. If the initialization of scrollbar and the handling
// of a scroll event are bundled in the sae useEffect, this re-initializes
// the scrollbar for every scroll event which basically screws everything up.
export default function useSmoothScrollbar(options = {}, onScroll) {
  const elemRef = useRef(null);
  const scrollRef = useRef(null);
  const [settings] = useState({
    ...defaultOptions,
    ...options,
  });

  useEffect(() => {
    if (elemRef.current) {
      const scrollbar = Scrollbar.init(elemRef.current, settings);
      scrollRef.current = scrollbar;

      return () => {
        scrollRef.current.destroy();
        scrollRef.current = null;
      }
    }
  }, [settings]);

  useEffect(() => {
    if (onScroll && scrollRef.current) {
      scrollRef.current.addListener(onScroll);

      return () => {
        if (scrollRef.current) {
          scrollRef.current.removeListener(onScroll);
        }
      }
    }
  }, [onScroll]);

  return [elemRef, scrollRef.current];
}
