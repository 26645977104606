import { useIsDesktop } from '../hooks';

export default function ViewportMultiplex(props) {
  const {
    desktop,
    mobile,
  } = props;

  const isDesktop = useIsDesktop();

  return isDesktop ? desktop : mobile;
}
