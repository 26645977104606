// ---------------- DEPENDENCIES --------------- //

import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../constants';

// ---------------- MAIN ---------------- //

export const useStyles = makeStyles({
  work: {
    height: '100%',
  },
  vLine: {
    position: 'absolute',
    top: 0,
    height: '100vh',
    backgroundColor: Colors.White,
    opacity: 0.9,
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row nowrap',
    overflow: 'hidden',
  },
  column: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    flex: 1,
  },
  projectList: {
    marginLeft: 'calc(100vw / 6)',
    margin: '0 calc(100vw / 6)',
    height: '100vh',
    overflowY: 'scroll',
    padding: '60px 0',
  },
  projectListItem: {
    opacity: 0.9,
    cursor: 'pointer',
    width: 'auto',
    fontSize: 20,
  },
  projectListItemStripeWrapper: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  projectListItemStripeClicked: {
    left: 'unset',
    right: 0,
  },
  ProjectDetails: {
    position: 'fixed',
    backgroundColor: Colors.White,
    zIndex: 999,
    padding: '40px 20px 20px 20px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow : 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  projectDetailsCloseButton: {
    backgroundColor: Colors.Black,
    border: `1px solid ${Colors.Red}`,
    color: Colors.White,
    borderRadius: 999,
    height: 35,
    width: 35,
  },
  projectDetailsCloseButtonIcon: {
    color: Colors.White,
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(2px, 2px)',
    fontSize: 30,
  },
  projectDetailsTitle: {
    color: '#333333',
    margin: 0,
    paddingBottom: 20,
    textAlign: 'center',
  },
  projectDetailsDescription: {
    color: '#666666',
    textAlign: 'center',
    width: '100%',
  },
  mobileTop: {
    overflowY: 'scroll',
  },
  mobileBottom: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  projectDetailsRepoLink: {
    color: Colors.Red,
    fontWeight: 700,
    fontSize: 20,
  },
});
