import { useEffect, useState } from 'react';

const THRESHOLD = 1000;

export default function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState(true);

  function handleViewportResize() {
    const viewportWidth = window.innerWidth;
    if (viewportWidth < THRESHOLD) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  }

  useEffect(function() {
    handleViewportResize();
    window.addEventListener('resize', handleViewportResize);

    return function() {
      window.removeEventListener('resize', handleViewportResize);
    }
  }, []);

  return isDesktop;
}
