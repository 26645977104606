// --------------- DEPENDENCIES -------------- //

import React, {
  useState,
} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { motion } from 'framer-motion';

// --------------- LOCAL DEPENDENCIES -------------- //

// Constants.
import { Routes, Colors } from '../../constants';

// Styles.
import { useStyles } from './styles';

// --------------- MAIN --------------- //

const links = [
  {
    label: 'Work',
    to: Routes.Work,
  },
  {
    label: 'About',
    to: Routes.About,
  },
];

function LinkBarAnim(props) {
  const { children, animate, ...other } = props;

  const variants = {
    blur: {
      width: '0%',
    },
    hover: {
      width: '100%',
    },
  };

  return (
    <motion.span
      variants={variants}
      animate={animate}
      {...other}
    >
      {children}
    </motion.span>
  );
}

function getLogoColor(history) {
  switch (history.location.pathname) {
    case Routes.Work: return Colors.Red;
    default: return Colors.White;
  }
}

function LinkBar(props) {
  const classes = useStyles(props);
  const [hover, setHover] = useState(false);
  const { active, children, to } = props;

  return (
    <Link
      to={to}
      className={classes.linkBar}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <LinkBarAnim
        className={classes.linkBarAnim}
        animate={hover || active ? 'hover' : 'blur'}
      />
      {children}
    </Link>
  );
}

function isActive(history, route) {
  return history.location.pathname === route;
}

function Navbar(props) {
  const classes = useStyles(props);
  const { history } = props;

  return (
    <div className={classes.navbar}>
      <Link
        className={classes.logo}
        style={{ color: getLogoColor(history) }}
        to={Routes.Landing}
      >
        <span>S</span>
        <sup>2</sup>
      </Link>
      <div className={classes.navlinks}>
        {links.map((link) => (
          <LinkBar
            key={link.label}
            active={isActive(history, link.to)}
            to={link.to}
          >
            <span className={classes.linkLabel}>
              {link.label}
            </span>
          </LinkBar>
        ))}
      </div>
    </div>
  );
}

export default compose(
  withRouter,
)(Navbar);
