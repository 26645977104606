// ---------------- DEPENDENCIES --------------- //

import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../constants';

// ---------------- MAIN ---------------- //

export const useStyles = makeStyles({
  work: {
    height: '100%',
  },
  vLine: {
    position: 'absolute',
    top: 0,
    height: '100vh',
    backgroundColor: Colors.White,
    opacity: 0.9,
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row nowrap',
    overflow: 'hidden',
  },
  column: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    flex: 1,
  },
  columnDetails: {
    '@media only screen and (max-width: 800px)': {
      display: 'none',
    },
  },
  projectList: {
    marginLeft: 'calc(100vw / 6)',
    '@media only screen and (max-width: 800px)': {
      margin: '0 calc(100vw / 6)',
    },
  },
  projectListItem: {
    opacity: 0.9,
    cursor: 'pointer',
    width: 'auto',
    fontSize: 20,
  },
  projectListItemStripeWrapper: {
    position: 'relative',
    width: 'fit-content',
    '@media only screen and (max-width: 800px)': {
      textAlign: 'center',
      width: '100%',
    },
  },
  projectListItemStripe: {
    backgroundColor: Colors.Red,
    height: 3,
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(50%)',
    '@media only screen and (max-width: 800px)': {
      display: 'none',
    },
  },
  projectListItemStripeClicked: {
    left: 'unset',
    right: 0,
  },
  projectsDetailedList: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
});
