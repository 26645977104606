// ---------------- DEPENDENCIES --------------- //

import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../constants';

// ---------------- MAIN ---------------- //

export const useStyles = makeStyles({
  projectDetails: {
    position: 'absolute',
    height: 300,
    width: 'calc(100vw / 3)',
  },
  background: {
    height: '100%',
    width: '100%',
    backgroundColor: Colors.White,
    borderRadius: 5,
    boxShadow: `rgba(0, 0, 0, .09) 0px 2px 23px 0px`,
    padding: 20,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    overflowY: 'scroll',
  },
  title: {
    color: '#333333',
    margin: 0,
  },
  description: {
    color: '#666666',
  },
  repoLink: {
    color: Colors.Red,
    fontWeight: 700,
    fontSize: 20,
  }
});
