// ---------------- DEPENDENCIES --------------- //

import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../constants';

// ---------------- MAIN ---------------- //

export const useStyles = makeStyles({
  navbar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: 70,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    padding: '10px 30px 0 30px',
    boxSizing: 'border-box',
    '@media only screen and (max-width: 800px)': {
      padding: '20px 20px 0 10px',
    },
  },
  logo: {
    textDecoration: 'none',
    color: Colors.White,
    fontSize: 65,
    fontWeight: '700',
    paddingLeft: 12,
    '& > sup': {
      display: 'inline-block',
      fontSize: 20,
      transform: 'translateY(-15px)',
    },
    '@media only screen and (max-width: 800px)': {
      fontSize: 45,
    },
  },
  navlinks: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '& > *': {
      marginLeft: 30,
    },
  },
  linkBar: {
    position: 'relative',
    textDecoration: 'none',
  },
  linkBarAnim: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    height: 2,
    backgroundColor: Colors.Red,
  },
  linkLabel: {
    color: Colors.Red,
    fontSize: 18,
  },
});
