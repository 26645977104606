/**
 * Naively setting state on event mouse move event was incurring large performance drawbacks.
 *
 * So, I decided to reduce the number of samples taken, as the UI updates using the position
 * end up using css animations to smooth the transition anyway.
 *
 * `latestPos` always stores the latest position. the `position` state however is only
 * updated every X milliseconds, as well as on mouse move start and end.
 */

import { useEffect, useState, useRef } from 'react';

export default function useMousePositionDebounced(sampleRateInit = 100) {
  const [sampleRate, setSampleRate] = useState(sampleRateInit);
  const [position, setPosition] = useState(null);
  const lastUpdateRef = useRef(Date.now());
  const timeoutRef = useRef(null);
  const latestPositionRef = useRef(null);

  // Track sample rate param.
  useEffect(() => {
    setSampleRate(sampleRateInit);
  }, [sampleRateInit]);

  function handleMouseMove(event) {
    const now = Date.now();
    const nextPos = [event.clientX, event.clientY];
    latestPositionRef.current = nextPos;

    const sinceLastUpdate = now - lastUpdateRef.current;
    // Started moving or moving.
    if (timeoutRef.current === null || sinceLastUpdate >= sampleRate) {
      setPosition(nextPos);
      lastUpdateRef.current = now;
    }

    // Set timeout for moveend event.
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(function mouseMoveEndHandler() {
      setPosition(latestPositionRef.current);
      lastUpdateRef.current = Date.now();
    }, sampleRate - sinceLastUpdate);
  }

  // Subscribe to mousemove event.
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return function mouseMoveListenerCleanup() {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return position;
}
