// ---------------- DEPENDENCIES --------------- //

import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../constants';

// ---------------- MAIN ---------------- //

export const useStyles = makeStyles({
  about: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100vh',
    overflowY: 'scroll',
    '@media only screen and (max-width: 800px)': {
      flexFlow: 'column-reverse nowrap',
      height: 'unset',
    },
  },
  left: {
    backgroundColor: Colors.Red,
    flex: 10,
    padding: 50,
    position: 'relative',
    '@media only screen and (max-width: 800px)': {
      height: '80vw',
    },
  },
  right: {
    flex: 12,
    backgroundColor: Colors.Black,
    padding: '0 70px 0 50px',
    boxSizing: 'border-box',
    height: '100%',
    overflowY: 'scroll',
    '& > .scroll-content': {
      padding: '70px 0',
    },
    '@media only screen and (max-width: 800px)': {
      padding: '40px 20px 50px 20px',
    }
  },
  header: {
    paddingTop: 30,
    '&, & *': {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: '1px',
      color: Colors.Red,
    },
  },
  divider: {
    backgroundColor: Colors.Red,
    width: 32,
    height: 2,
    margin: '20px 0',
  },
  paragraph: {
    fontSize: 32,
    lineHeight: '42px',
    fontWeight: 700,
    color: Colors.Red,
    '& > *': {
      display: 'inline-block',
    },
    '@media only screen and (max-width: 800px)': {
      fontSize: 24,
    },
  },
  white: {
    color: Colors.White,
  },
  skillsContainer: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingBottom: 70,
    '@media only screen and (max-width: 800px)': {
      justifyContent: 'space-around',
    },
  },
  skillHeader: {
    fontSize: 32,
    lineHeight: '42px',
    fontWeight: 700,
    color: Colors.White,
    '& > *': {
      display: 'inline-block',
    },
    '@media only screen and (max-width: 800px)': {
      fontSize: 24,
    },
  },
  email: {
    color: Colors.Red,
    textDecoration: 'none',
    borderBottom: `2px solid ${Colors.Red}`,
    paddingBottom: 3,
  },
});
