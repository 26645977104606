import React from 'react';

const projects = [
  {
    title: 'Personal Website',
    description: (
      <>
        This is the codebase for this very website!
      </>
    ),
    repositoryLink: 'https://gitlab.com/sai.sulaj/saimir',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Digital Marketing Blog',
    description: (
      <>
        Aflo is a community for marketers and entrepreneurs to find the best digital marketing strategies.
      </>
    ),
    repositoryLink: 'https://gitlab.com/sai.sulaj/aflo-marketplace',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Digital Marketing Blog — SSR',
    description: (
      <>
        Aflo is a community for marketers and entrepreneurs to find the best digital marketing strategies. This is a clone of the original site above that utilizes server-side rendering for SEO optimization.
      </>
    ),
    repositoryLink: 'https://www.aflo.io',
    repoLabel: 'Check it out here',
  },
  {
    title: 'Rust — Doing',
    description: (
      <>
        This is a very simple cli-based TODO logger, loosly based on&nbsp;
        <a
          href="https://github.com/ttscoff/doing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          doing (I'm not good with names okay)
        </a>
        . All written in Rust, and very much a work-in-progress.
      </>
    ),
    repositoryLink: 'https://gitlab.com/sai.sulaj/doing-rust',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Cipher Algorithms Written in Rust',
    description: (
      <>
        This was a project to strengthen my skills in Rust, as well as an introduction to Cryptography.
        <br /><br />
        This library includes traditional ciphers such as the Vigenere cipher, as well as moden ciphers such as the DES cipher.
      </>
    ),
    repositoryLink: 'https://gitlab.com/sai.sulaj/ciphers',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Game of Life Written in Rust',
    description: (
      <>
        This was a project to strengthen my skills in Rust.
        <br /><br />
        The project can be compiled to an executable and run in the terminal with the ability to change the universe dimensions, number of starting cells, and the refresh period.
      </>
    ),
    repositoryLink: 'https://gitlab.com/sai.sulaj/game_of_life',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Multi-page platform practice',
    description: 'Adcentric is a generic analytics platform with Authentication, and Facebook integration.',
    repositoryLink: 'https://gitlab.com/sai.sulaj/adcentric',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Market Maker Trading Bot',
    description: (
      <>
        The Market Maker Bot is a high-frequency trading bot that profits on large spreads between buy and ask prices in cryptocurrency markets.
        <br /><br />
        The bot fetches data from the Data Vendor API, another project of mine that fetches data from cryptocurrency markets and serves the cached, parsed data from a URL endpoint so the Market Maker does not have to worry about ratelimits or data parsing.
        <br /><br />
        The bot tracks price spreads and in the event of a sufficiently high spread, places a bid order above the highest ask price and waits for order fulfillment. The open order is constantly tracked and updated to stay right above the highest ask. In the event that the order is filled, an ask order is immediately placed at the midpoint of the price spread with the new coins. The bot is optimized to be holding non-fiat coins for as little as possible, and features a console UI to track progress and state.
      </>
  ),
    repositoryLink: 'https://gitlab.com/sai.sulaj/MarketMakerBot',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Cryptocurrency Data Vendor API',
    description: (
      <>
        The Data Vendor API is a server that takes the responsibility of abiding by exchange ratelimits, caching relevant data, and parsing returned exchange data away from individual trading strategies.
        <br /><br />
        URL endpoints serve exchange orderbook data, OHLCV data, and technical indicators that require data to be cached such as moving averages from various exchanges.
      </>
    ),
    repositoryLink: 'https://gitlab.com/sai.sulaj/DataVendor',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Airbnb Clone',
    description: 'To give myself a challenge, I decided to clone Airbnb\'s website using React JS. No 3rd-party UI libraries were used, everything is custom built. My reasons for choosing Airbnb include the relatively stark difference in the mobile and desktop versions of the site, and the variety of different UI components that provide a diverse set of challenges during development.',
    repositoryLink: 'https://gitlab.com/sai.sulaj/airbnb-clone',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Price Channel Bot',
    description: (
      <>
        The Price Channel Bot monitors cryptocurrency market orderbooks and detects 'price channels'. Price channels are phenomena that occur when big-movers leverage their assets to artificially keep the value of a coin above or below a certain price.
        <br /><br />
        These attempts at price manipulation manifest themselves as large spikes in orderbook volume at specific prices. When there is a wall in both a coin's bid and ask prices, a channel appears for the coin's price to reside in.
        <br /><br />
        These provide a unique opportunity, as the walls act as formidable support and resistance lines that restrict the price's movements. This bot detects these phenomena and updates a Firebase database real-time to inform the trader of potential opportunities.
      </>
    ),
    repositoryLink: 'https://gitlab.com/sai.sulaj/pricechannelbot',
    repoLabel: 'Check out the code here',
  },
  {
    title: 'Frontier Token',
    description: 'The Frontier Token is an ERC20-compliant cryptocurrency built using the solidity programming language for the Ethereum framework. The token is burnable, mintable, and ownable by multiple parties.',
    repositoryLink: 'https://gitlab.com/sai.sulaj/FrontierToken',
    repoLabel: 'Check out the code here',
  },
];

export default projects;
