// --------------- DEPENDENCIES -------------- //

import React, {
  useState,
  useEffect,
} from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { MdClose } from 'react-icons/md';

// --------------- LOCAL DEPENDENCIES -------------- //

import './custom.css';

// Constants.
import { Routes } from '../../constants';
import projects from './projects';

// Hooks.
import { usePrevious } from '../../hooks';

// Styles.
import { useStyles } from './mobileStyles';

// --------------- MAIN --------------- //

function VLine(props) {
  const { delta, offset, ...other } = props;

  const variants = {
    visible: {
      width: `calc(${delta}vw - 1px)`,
      left: `${offset}vw`,
      x: 0,
      transition: {
        duration: 0.7,
      },
    },
    hidden: {
      x: -1,
      width: 1,
      left: `${offset}vw`,
      transition: {
        duration: 0.3,
        delay: 0.8,
      },
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      {...other}
    />
  );
}

const projectListVariants = {
  visible: {
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.5,
      staggerDirection: 1,
    },
  },
  hidden: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const projectListItemVariants = {
  visible: {
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 40,
    },
  },
  hidden: {
    y: '100vh',
    transition: {
      type: 'spring',
      stiffness: 40,
    },
  },
};

function ProjectListItem(props) {
  const classes = useStyles(props);
  const {
    children,
    onClick,
  } = props;

  return (
    <motion.h2
      className={classes.projectListItem}
      variants={projectListItemVariants}
      onClick={onClick}
    >
      <div className={classes.projectListItemStripeWrapper}>
        {children}
      </div>
    </motion.h2>
  );
}

function VLines(props) {
  const classes = useStyles(props);
  const { number = 5 } = props;

  const delta = 100 / number;
  return _.range(0, number).map((index) => {
    const offset = delta * index;

    return (
      <VLine
        key={index}
        className={classes.vLine}
        delta={delta}
        offset={offset}
      />
    );
  });
}

function ProjectDetails(props) {
  const { children, open, ...other } = props;

  const projectDetailsDelayedTransitionOpen = {
    ease: 'circIn',
    duration: 0.8,
  };
  const projectDetailsDelayedTransitionClosed = {
    ease: 'circOut',
    duration: 0.8,
    delay: 100,
  };
  const variants = {
    open: {
      y: 0,
      height: '100vh',
      width: '100vw',
      left: 0,
      top: 0,
      borderRadius: 0,
      transition: {
        type: 'tween',
        y: {
          ease: 'circOut',
          duration: 0.8,
        },
        height: projectDetailsDelayedTransitionOpen,
        width: projectDetailsDelayedTransitionOpen,
        left: projectDetailsDelayedTransitionOpen,
        top: projectDetailsDelayedTransitionOpen,
        borderRadius: projectDetailsDelayedTransitionOpen,
      },
    },
    closed: {
      y: '100vh',
      height: '90vh',
      width: '90vw',
      left: '5vw',
      top: '5vh',
      borderRadius: 20,
      transition: {
        type: 'tween',
        y: {
          ease: 'circIn',
          duration: 0.8,
          delay: 0.1,
        },
        height: projectDetailsDelayedTransitionClosed,
        width: projectDetailsDelayedTransitionClosed,
        left: projectDetailsDelayedTransitionClosed,
        top: projectDetailsDelayedTransitionClosed,
        borderRadius: projectDetailsDelayedTransitionClosed,
      },
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="closed"
      animate={open ? 'open' : 'closed'}
      {...other}
    >
      {children}
    </motion.div>
  );
}

function ProjectDetailsCloseButton(props) {
  const { children, open, ...other } = props;

  const variants = {
    open: {
      scale: 1,
      rotateZ: '0deg',
      applyAtStart: { display: 'unset' },
      transition: { delay: 0.9 },
    },
    closed: {
      scale: 0,
      rotateZ: '360deg',
      applyAtEnd: { display: 'end' },
    },
  };

  return (
    <motion.button
      variants={variants}
      initial="closed"
      animate={open ? 'open' : 'closed'}
      {...other}
    >
      {children}
    </motion.button>
  );
}

function WorkMobile(props) {
  const classes = useStyles(props);
  const [activeIndex, setActiveIndex] = useState(-1);
  const { history } = props;

  const prevActiveIndex = usePrevious(activeIndex);

  useEffect(() => {
    return () => {
      if (history.location.pathname !== Routes.Work) {
        setActiveIndex(-1);
      }
    }
  }, [history.location.pathname]);

  const mobileIndex = activeIndex === -1 ? prevActiveIndex : activeIndex;
  return (
    <div className={classes.work}>
      <VLines number={4} />
      <div className={classes.content}>
        <div
          className={classes.column}
        >
          <motion.div
            className={classes.projectList}
            variants={projectListVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {projects.map((project, index) => (
              <ProjectListItem
                active={index === activeIndex}
                onClick={() => setActiveIndex(index)}
              >
                {project.title}
              </ProjectListItem>
            ))}
          </motion.div>
        </div>
        <ProjectDetails
          className={classes.ProjectDetails}
          open={activeIndex !== -1}
        >
          {mobileIndex !== -1 && (
            <>
              <div className={classes.mobileTop}>
                <h2 className={classes.projectDetailsTitle}>
                  {projects[mobileIndex].title}
                </h2>
                <p className={classes.projectDetailsDescription}>
                  {projects[mobileIndex].description}
                </p>
              </div>
              <div className={classes.mobileBottom}>
                {projects[mobileIndex].repositoryLink !== null && (
                  <a
                    href={projects[mobileIndex].repositoryLink}
                    className={classes.projectDetailsRepoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {projects[mobileIndex].repoLabel}
                  </a>
                )}
                <ProjectDetailsCloseButton
                  open={activeIndex !== -1}
                  className={classes.projectDetailsCloseButton}
                  onClick={() => setActiveIndex(-1)}
                >
                  <MdClose
                    className={classes.projectDetailsCloseButtonIcon}
                  />
                </ProjectDetailsCloseButton>
              </div>
            </>
          )}
        </ProjectDetails>
      </div>
    </div>
  );
}

export default WorkMobile;
