// --------------- DEPENDENCIES -------------- //

import React from 'react';
import { motion } from 'framer-motion';

// --------------- LOCAL DEPENDENCIES -------------- //

// Components.
import PortraitImage from '../../components/PortraitImage';
import SkillItem from '../../components/SkillItem';

// Hooks.
import { useTileTilt, useSmoothScrollbar } from '../../hooks';

// Constants.
import { Colors } from '../../constants';

// Styles.
import { useStyles } from './styles';

// Media.
import AndroidIcon from '../../assets/skills/android.svg';
import AssemblyIcon from '../../assets/skills/assembly.svg';
import BashIcon from '../../assets/skills/bash.svg';
import CIcon from '../../assets/skills/c-programming.svg'
import FastAIIcon from '../../assets/skills/fastai.png';
import JavaIcon from '../../assets/skills/java.svg';
import JavascriptIcon from '../../assets/skills/javascript.svg';
import NodeJSIcon from '../../assets/skills/node-js.svg';
import PythonIcon from '../../assets/skills/python.svg';
import ReactJSIcon from '../../assets/skills/react.svg';
import VerilogIcon from '../../assets/skills/verilog.svg';
import AWSIcon from '../../assets/skills/aws.svg';
import PytorchIcon from '../../assets/skills/pytorch.svg';
import RustIcon from '../../assets/skills/rust.svg';

// --------------- CONSTANTS --------------- //

const primarySkills = [
  {
    label: 'Rust',
    image: RustIcon,
  },
  {
    label: 'C',
    image: CIcon,
  },
  {
    label: 'React JS',
    image: ReactJSIcon,
  },
  {
    label: 'Javascript',
    image: JavascriptIcon,
  },
  {
    label: 'Node JS',
    image: NodeJSIcon,
  },
  {
    label: 'AWS',
    image: AWSIcon,
  },
];

const secondarySkills = [
  {
    label: 'Python',
    image: PythonIcon,
  },
  {
    label: 'ARM Assembly',
    image: AssemblyIcon,
  },
  {
    label: 'Fast AI',
    image: FastAIIcon,
  },
  {
    label: 'Bash',
    image: BashIcon,
  },
  {
    label: 'Pytorch',
    image: PytorchIcon,
  },
];

const tertiarySkills = [
  {
    label: 'Android',
    image: AndroidIcon,
  },
  {
    label: 'Java',
    image: JavaIcon,
  },
  {
    label: 'Verilog',
    image: VerilogIcon,
  },
];

// --------------- MAIN --------------- //

const EnterSide = React.forwardRef(function (props, ref) {
  const {
    children,
    direction,
    ...other
  } = props;

  const variants = {
    visible: {
      x: 0,
      transition: {
        type: 'tween',
        duration: 0.5,
        delayChildren: 0.05,
        staggerChildren: 0.07,
      },
    },
    hidden: {
      x: direction === 'right' ? '100vw' : '-100vw',
      transition: {
        type: 'tween',
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <motion.div
      positionTransition
      ref={ref}
      variants={variants}
      {...other}
    >
      {children}
    </motion.div>
  );
});

function WhiteText(props) {
  const classes = useStyles(props);
  const { children, href } = props;

  const Tag = href === undefined ? 'span' : 'a';
  const words = children.split(' ');

  return (
    <>
      {words.map((word, i) => (
        <Tag
          target="_blank"
          href={href}
          key={word}
          className={classes.white}
        >
          {word}
          {i < words.length - 1 && (
            <>&nbsp;</>
          )}
        </Tag>
      ))}
    </>
  );
}

const horizontalMaskVariants = {
  visible: {
    position: 'absolute',
    height: '100%',
    width: '0%',
    top: 0,
    right: 0,
    backgroundColor: Colors.Red,
    transition: {
      type: 'tween',
      duration: 0.7,
      delay: 0.7,
    },
  },
  hidden: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    backgroundColor: Colors.Red,
  },
};

function About(props) {
  const classes = useStyles(props);

  const [
    trackRef,
    hoverTrackerProps,
    { onScroll },
    tiltStyle,
  ] = useTileTilt({
    reverse: true,
    scale: 1.03,
  });
  const [scrollRef] = useSmoothScrollbar(undefined, onScroll);

  return (
    <div className={classes.about}>
      <EnterSide
        ref={trackRef}
        direction="left"
        className={classes.left}
        {...hoverTrackerProps}
      >
        <PortraitImage tiltStyle={tiltStyle}/>
        <motion.div
          variants={horizontalMaskVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        />
      </EnterSide>
      <EnterSide
        ref={scrollRef}
        direction="right"
        className={classes.right}
      >
        <div className={classes.header}>
          <EnterSide direction="right">
            <h2>
              HOLA
            </h2>
          </EnterSide>
          <EnterSide direction="right">
            <div className={classes.divider} />
          </EnterSide>
        </div>
        <EnterSide direction="right">
          <p className={classes.paragraph}>
            <WhiteText>
              I am Saimir Sulaj
            </WhiteText>
            , A Full-Stack developer based in&nbsp;
            <WhiteText>
              Vancouver, BC.
            </WhiteText>
          </p>
        </EnterSide>
        <EnterSide direction="right">
          <p className={classes.paragraph}>
            I am currently working on&nbsp;
            <WhiteText href="https://www.getuvx.com/">
              UVX
            </WhiteText>
            . We have built a&nbsp;
            <WhiteText>
              smart&nbsp;
            </WhiteText>
              ceiling device that&nbsp;
            <WhiteText>
              improves disinfection&nbsp;
            </WhiteText>
            in senior homes using&nbsp;
            <WhiteText>
              human-safe UV light&nbsp;
            </WhiteText>
            disinfection.
          </p>
        </EnterSide>
        <div className={classes.header}>
          <EnterSide direction="right">
            <h2>
              MAIN SKILLS
            </h2>
          </EnterSide>
          <EnterSide direction="right">
            <div className={classes.divider} />
          </EnterSide>
        </div>
        <EnterSide direction="right">
          <span className={classes.skillHeader}>
            I am confident with
          </span>
        </EnterSide>
        <div className={classes.skillsContainer}>
          {primarySkills.map((skill) => (
            <EnterSide
              key={skill.label}
              direction="right"
            >
              <SkillItem
                icon={skill.image}
                label={skill.label}
              />
            </EnterSide>
          ))}
        </div>
        <EnterSide direction="right">
          <span className={classes.skillHeader}>
            I have experience with
          </span>
        </EnterSide>
        <div className={classes.skillsContainer}>
          {secondarySkills.map((skill) => (
            <EnterSide
              key={skill.label}
              direction="right"
            >
              <SkillItem
                icon={skill.image}
                label={skill.label}
              />
            </EnterSide>
          ))}
        </div>
        <EnterSide direction="right">
          <span className={classes.skillHeader}>
            I am familiar with
          </span>
        </EnterSide>
        <div className={classes.skillsContainer}>
          {tertiarySkills.map((skill) => (
            <EnterSide
              key={skill.label}
              direction="right"
            >
              <SkillItem
                icon={skill.image}
                label={skill.label}
              />
            </EnterSide>
          ))}
        </div>
        <div className={classes.header}>
          <EnterSide direction="right">
            <h2>
              CONTACT
            </h2>
          </EnterSide>
          <EnterSide direction="right">
            <div className={classes.divider} />
          </EnterSide>
        </div>
        <EnterSide direction="right">
          <p className={classes.paragraph}>
            <WhiteText>
              Let's work together?
            </WhiteText>
            <a
              className={classes.email}
              href="mailto:hola@saimirsulaj.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              hola@saimirsulaj.com
            </a>
          </p>
        </EnterSide>
      </EnterSide>
    </div>
  );
}

export default About;
