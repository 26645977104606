// ---------------- DEPENDENCIES --------------- //

import { makeStyles } from '@material-ui/styles';

// ---------------- MAIN ---------------- //

export const useStyles = makeStyles({
  portraitImageRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    pointerEvents: 'none',
  },
  content: {
    width: '34vw',
    height: '34vw',
    position: 'relative',
    '@media only screen and (max-width: 800px)': {
      width: '90vw',
      height: '90vw',
    },
  },
  portraitImage: {
    position: 'absolute',
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  portraitBackgroundWrapper: {
    width: '34vw',
    height: '34vw',
    position: 'relative',
    perspectiveOrigin: 'center center',
    '@media only screen and (max-width: 800px)': {
      width: '90vw',
      height: '90vw',
    },
  },
  photoCredits: {
    position: 'absolute',
    top: 0,
    right: -21,
    transformOrigin: '0% 0%',
    fontSize: 14,
    color: 'white',
    fontWeight: 300,
    transform: `
      translate(100%, 0%)
      translateZ(0)
      rotate(90deg)
    `,
    '@media only screen and (max-width: 800px)': {
      display: 'none',
    }
  },
  portraitBackground: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  mouseEventPane: {
    position: 'absolute',
    width: '34vw',
    height: '34vw',
    top: 0,
    left: 0,
  },
});
