// --------------- DEPENDENCIES -------------- //

import React from 'react';

// --------------- LOCAL DEPENDENCIES -------------- //

// Styles.
import { useStyles } from './styles';

// Assets.
import portraitImage from '../../assets/portrait.png';
import portraitBgImage from '../../assets/portrait-background.jpg';

// --------------- MAIN --------------- //

function PortraitImage(props) {
  const classes = useStyles(props);
  const { tiltStyle } = props;

  return (
    <div className={classes.portraitImageRoot}>
      <div className={classes.content}>
        <div
          className={classes.portraitBackgroundWrapper}
          style={tiltStyle}
        >
          <img
            src={portraitBgImage}
            className={classes.portraitBackground}
            alt=""
          />
          <span className={classes.photoCredits}>
            PHOTO CREDITS @ DAMIR NINKOVIĆ (@daymur)
          </span>
        </div>
        <img
          className={classes.portraitImage}
          src={portraitImage}
          alt=""
        />
        <div className={classes.mouseEventPane} />
      </div>
    </div>
  );
}

export default PortraitImage;
