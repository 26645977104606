// --------------- DEPENDENCIES -------------- //

import React from 'react';

// --------------- LOCAL DEPENDENCIES -------------- //

// Styles.
import { useStyles } from './styles';

// --------------- MAIN --------------- //

function SkillItem(props) {
  const classes = useStyles(props);
  const { icon, label } = props;

  return (
    <div className={classes.skillItem}>
      <img
        src={icon}
        alt=""
      />
      <span>
        {label}
      </span>
    </div>
  );
}

export default SkillItem;
