// --------------- DEPENDENCIES -------------- //

import React from 'react';
import { withRouter } from 'react-router-dom';

// --------------- LOCAL DEPENDENCIES -------------- //

// Pages.
import WorkDesktop from './WorkDesktop';
import WorkMobile from './WorkMobile';

// Components.
import ViewportMultiplex from '../../components/ViewportMultiplex';

function Work(props) {
  return (
    <ViewportMultiplex
      desktop={<WorkDesktop {...props}/>}
      mobile={<WorkMobile {...props}/>}
    />
  );
}

export default withRouter(Work);
