// --------------- DEPENDENCIES -------------- //

import React from 'react';

// --------------- LOCAL DEPENDENCIES -------------- //

// Hooks.
import { usePrevious } from '../../hooks';

// Styles.
import { useStyles } from './styles';

// --------------- MAIN --------------- //

const ease = 'cubic-bezier(0.80, 0, 0.20, 1)';
const duration = 800;

function ProjectDetails(props) {
  const classes = useStyles(props);
  const {
    project,
    index,
    activeIndex,
  } = props;
  const prevActiveIndex = usePrevious(activeIndex);

  const prevDelta = index - prevActiveIndex;
  const delta = index - activeIndex;
  const scale = delta >= 0 ? 1 : 0.8;
  return (
    <div
      className={classes.projectDetails}
      style={{
        transform: `translateX(${delta > 0 ? 50 : 0}vw)`,
        transition: `
          transform ${duration}ms ${ease} ${Math.abs((prevDelta - 1) * 200)}ms
        `,
      }}
    >
      <div
        className={classes.background}
        style={{
          transform: `scale(${scale}, ${scale})`,
          transition: `
            transform ${duration}ms ${ease} ${Math.abs(prevDelta * 200)}ms
          `,
        }}
      >
        <div>
          <h2 className={classes.title}>
            {project.title}
          </h2>
          <p>
            {project.description}
          </p>
        </div>
        {project.repositoryLink !== null && (
          <a
            className={classes.repoLink}
            href={project.repositoryLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {project.repoLabel}
          </a>
        )}
      </div>
    </div>
  );
}

export default ProjectDetails;
