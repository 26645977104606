// ---------------- DEPENDENCIES --------------- //

import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../constants';

// ---------------- MAIN ---------------- //

export const useStyles = makeStyles({
  landing: {},
  particles: {
    height: '100vh',
    width: '100vw',
  },
  welcome: {
    position: 'absolute',
    left: 50,
    bottom: 50,
    width: 'calc(100vw - 100px)',
    color: Colors.White,
    margin: 0,
    fontSize: 50,
    '@media only screen and (max-width: 800px)': {
      fontSize: 32,
    },
    '& > span': {
      display: 'inline-block',
    },
  },
  mobileHidden: {
    '@media only screen and (max-width: 800px)': {
      display: 'none',
    },
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '@media only screen and (max-width: 800px)': {
      flexFlow: 'column nowrap',
    },
  },
  dividerDesktop: {
    backgroundColor: Colors.Red,
    height: 35,
    width: 1,
    margin: '0 30px',
  },
  dividerMobile: {
    backgroundColor: Colors.Red,
    height: 1,
    width: 50,
    margin: '10px 0',
  },
  actionLink: {
    color: Colors.White,
    textDecoration: 'none',
    fontSize: 35,
    '@media only screen and (max-width: 800px)': {
      fontSize: 50,
    },
  },
});
