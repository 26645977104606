// --------------- DEPENDENCIES -------------- //

import React from 'react';
import Particles from 'react-particles-js';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { Link } from 'react-router-dom';

// --------------- LOCAL DEPENDENCIES -------------- //

// Constants.
import { Colors, Routes } from '../../constants';

// Hooks.
import { useIsDesktop } from '../../hooks';

// Styles.
import { useStyles } from './styles';

// --------------- MAIN --------------- //

const buildShadow = (n, init) => _.range(1, n + 1)
  .reduce((acc, curr) => {
    const val = init ? 0 : curr;
    let next = ` -${val}px ${val}px ${Colors.Red}`;

    if (curr !== 1) {
      next = `, ${next}`;
    }

    return `${acc}${next}`;
  }  , '');

const welcomeVariants = {
  visible: {
    x: 4,
    y: -4,
    textShadow: buildShadow(4),
    transition: { duration: 0.5 },
  },
  hidden: {
    x: 0,
    y: 0,
    textShadow: buildShadow(4, true),
    transition: { duration: 0.5 },
  },
};

const zoomInVariants = {
  visible: {
    scale: 1,
    transition: {
      type: 'spring',
      duration: 1,
      stiffness: 120,
    },
  },
  hidden: {
    scale: 0,
    transition: {
      type: 'tween',
      duration: 1,
      ease: 'anticipate',
    },
  },
};

function ActionLinkWrapper(props) {
  const { children, ...other } = props;

  const variants = {
    init: { y: 0 },
    hover: {
      y: -6,
      transition: {
        type: 'spring',
        stiffness: 90,
      },
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="init"
      whileHover="hover"
      {...other}
    >
      {children}
    </motion.div>
  );
}

function Landing(props) {
  const classes = useStyles(props);

  const isDesktop = useIsDesktop();

  return (
    <div className={classes.landing}>
      <Particles
        className={classes.particles}
      />
      <motion.div
        className={classes.welcome}
        variants={welcomeVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <span>Hey! I'm Saimir.</span>
        <span>
          <span className={classes.mobileHidden}>&nbsp;</span>
          I Build Cool Things Sometimes.
        </span>
      </motion.div>
      <div className={classes.content}>
        <motion.div
          variants={zoomInVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <div className={classes.actionContainer}>
            <ActionLinkWrapper>
              <Link
                className={classes.actionLink}
                to={Routes.Work}
              >
                work
              </Link>
            </ActionLinkWrapper>
            <div
              className={isDesktop ? classes.dividerDesktop : classes.dividerMobile}
            />
            <ActionLinkWrapper>
              <Link
                className={classes.actionLink}
                to={Routes.About}
              >
                about
              </Link>
            </ActionLinkWrapper>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Landing;
