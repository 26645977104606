// --------------- DEPENDENCIES -------------- //

import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import classnames from 'classnames';

// --------------- LOCAL DEPENDENCIES -------------- //

// Components.
import ProjectDetails from '../../components/ProjectDetails';

// Hooks.
import useMousePositionDebounced from '../../hooks/useMousePositionDebounced';

// Constants.
import projects from './projects';

// Styles.
import { useStyles } from './desktopStyles';

// --------------- MAIN --------------- //

function VLine(props) {
  const { delta, offset, ...other } = props;

  const variants = {
    visible: {
      width: `calc(${delta}vw - 1px)`,
      left: `${offset}vw`,
      x: 0,
      transition: {
        duration: 1,
      },
    },
    hidden: {
      x: -1,
      width: 1,
      left: `${offset}vw`,
      transition: {
        duration: 0.6,
        delay: 0.8,
      },
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      {...other}
    />
  );
}

const projectListVariants = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerDirection: 1,
      staggerChildren: 0.05,
      delayChildren: 1,
    },
  },
  hidden: {
    transition: {
      when: 'afterChildren',
      staggerDirection: -1,
      staggerChildren: 0.05,
    },
  },
};

const projectListItemVariants = {
  visible: {
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 40,
    },
  },
  hidden: {
    y: '100vh',
    transition: {
      type: 'spring',
      stiffness: 40,
    },
  },
};

const projectListItemStripeVariants = {
  none: { width: 0 },
  hover: { width: '100%' },
  click: { width: 0 },
};

function ProjectListItem(props) {
  const classes = useStyles(props);
  const {
    children,
    active,
    onClick,
  } = props;
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);
  const timeoutRef = useRef(null);

  function handleClick() {
    onClick();
    clearTimeout(timeoutRef.current);
    setClicked(true);

    timeoutRef.current = setTimeout(() => {
      setClicked(false);
    }, 500);
  }

  let animate = 'none';
  if (clicked) {
    animate = 'click';
  } else if (hover || active) {
    animate = 'hover';
  }

  return (
    <motion.h2
      positionTransition
      variants={projectListItemVariants}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >
      <div className={classes.projectListItemStripeWrapper}>
        {children}
        <motion.div
          variants={projectListItemStripeVariants}
          animate={animate}
          className={classnames(
            classes.projectListItemStripe,
            clicked && classes.projectListItemStripeClicked,
          )}
        />
      </div>
    </motion.h2>
  );
}

function VLines(props) {
  const classes = useStyles(props);
  const { number = 5 } = props;

  const delta = 100 / number;
  return _.range(0, number).map((index) => {
    const offset = delta * index;

    return (
      <VLine
        key={index}
        className={classes.vLine}
        delta={delta}
        offset={offset}
      />
    );
  });
}

function ProjectsDetailList(props) {
  const classes = useStyles(props);
  const { activeIndex } = props;

  return (
    <div
      className={classes.projectsDetailedList}
    >
      {projects.map((project, index) => {
        return (
          <ProjectDetails
            key={project.title}
            index={index}
            activeIndex={activeIndex}
            project={project}
          />
        );
      })}
    </div>
  );
}

function WorkDesktop(props) {
  const classes = useStyles(props);
  const [activeIndex, setActiveIndex] = useState(-1);
  const { history } = props;

  const mousePosition = useMousePositionDebounced();

  useEffect(() => {
    console.log(history.location.pathname);
    if (!history.location.pathname.endsWith('work')) { return; }
    setTimeout(() => {
      setActiveIndex(0);
    }, 1000);

    return () => {
      setActiveIndex(-1);
    };
  }, [history.location.pathname]);

  return (
    <div className={classes.work}>
      <VLines number={6} />
      <div className={classes.content}>
        <div
          className={classes.column}
        >
          <motion.div
            key="projects-container"
            className={classes.projectList}
            variants={projectListVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {projects.map((project, index) => (
              <ProjectListItem
                key={`desktop-project-list-item-${index}`}
                className={classes.projectListItem}
                active={index === activeIndex}
                onClick={() => setActiveIndex(index)}
              >
                {project.title}
              </ProjectListItem>
            ))}
          </motion.div>
        </div>
        <div className={classes.column}>
          <ProjectsDetailList
            activeIndex={activeIndex}
          />
        </div>
      </div>
    </div>
  );
}

export default WorkDesktop;
